<template>
  <div>
    <van-cell
      v-for="item in arr"
      :title="item.displayName"
      :key="item.tempCode"
      is-link
      @click="viewPdf(item.tempCode)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { getTempCodeApi } from '@/apis/repayment/index'
import { Cell, Divider } from 'vant'

Vue.use(Cell)
Vue.use(Divider)
export default {
  data() {
    return {
      loanNo: null,
      arr: [],
    }
  },
  created() {
    this.loanNo = this.$route.query.loanNo
    this.checkOut()
    console.log(this.loanNo, this.arr)
  },
  methods: {
    // 查看借款合同
    checkOut() {
      // 获取合同编码
      getTempCodeApi({ loanNo: this.loanNo }).then(res => {
        if (res.code == 200) {
          // 预览合同
          res.data.forEach(item => {
            this.arr.push(item)
            console.log(this.arr, this.loanNo)
          })
        }
      })
    },
    viewPdf(data) {
      this.$router.push({
        path: '/reviewContract',
        query: {
          businessNo: this.loanNo,
          tempCode: data,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
